<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" :page_title="$route.query.eventName" />

    <v-card class="mx-3 my-5 rounded-lg">
      <template>
        <v-container>
          <v-row class="d-flex">
            <v-col cols="12" lg="6" md="6" sm="12" class="pt-5 pb-5 pl-5 pr-2">
              <div class="img_container">
                <v-img
                  :src="detailItem.orgImage != null ? detailItem.orgImage : ''"
                  class="orgImg"
                  alt="OrgImg"
                ></v-img>
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12" class="pt-5 Casetext">
              <v-card-title style="font-size: 16px" v-if="!NullDescription">{{
                $t("description")
              }}</v-card-title>

              <v-card-subtitle
                style="font-size: 18px; opacity: 0.8"
                class="mt-3"
                v-if="!NullDescription"
              >
                <div>
                  <p class="breaktext">
                    {{
                      detailItem.wordDescription
                        ? detailItem.wordDescription
                        : ""
                    }}
                  </p>
                </div>
              </v-card-subtitle>

              <div class="d-flex mt-5">
                <v-col cols="12" md="4" lg="4" xl="4" class="textadjust">
                  <p class="pl-2 textadjust">{{ $t("startdate") }}</p>
                </v-col>
                <v-col class="textadjust">
                  <p style="font-size: 18px; opacity: 0.8" class="mb-0">
                    {{ eventStartDate + " - " + eventEndDate }}
                  </p>
                </v-col>
              </div>

              <div class="d-flex">
                <v-col cols="12" md="4" lg="4" xl="4" class="textadjust">
                  <p class="pl-2 textadjust">{{ $t("time") }}</p>
                </v-col>
                <v-col class="textadjust">
                  <p style="font-size: 18px; opacity: 0.8" class="mb-0">
                    {{ course_time }}
                  </p>
                </v-col>
              </div>

              <div class="d-flex">
                <v-col cols="12" md="4" lg="4" xl="4" class="textadjust">
                  <p class="pl-2 textadjust">{{ $t("locationsm") }}</p>
                </v-col>
                <v-col class="textadjust">
                  <p style="font-size: 18px; opacity: 0.8" class="mb-0">
                    {{
                      detailItem.courseLocation ? detailItem.courseLocation : ""
                    }}
                  </p>
                </v-col>
              </div>

              <div class="d-flex">
                <v-col cols="12" md="4" lg="4" xl="4" class="textadjust">
                  <p class="pl-2 textadjust">{{ $t("participants") }}</p>
                </v-col>
                <v-col class="textadjust">
                  <p style="font-size: 18px; opacity: 0.8" class="mb-0">
                    {{ $t("lblJoin").replace("{0}", joinUser) }}
                  </p>
                  <p style="font-size: 18px; opacity: 0.8" class="mb-0">
                    {{ $t("lblremainingseat").replace("{0}", remainingseat) }}
                  </p>
                </v-col>
              </div>

              <div class="d-flex">
                <v-col cols="12" md="4" lg="4" xl="4" class="textadjust">
                  <p class="pl-2 textadjust">{{ $t("inchargesm") }}</p>
                </v-col>
                <v-col class="textadjust">
                  <p style="font-size: 18px; opacity: 0.8" class="mb-0">
                    {{ detailItem.teacherName ? detailItem.teacherName : "" }}
                  </p>
                </v-col>
              </div>

              <div
                class="d-flex"
                v-if="checkEnrollUser == 'Enrolled' && showlink != null"
              >
                <v-col cols="12" md="4" lg="4" xl="4" class="textadjust">
                  <p class="pl-2 textadjust">{{ $t("link") }}</p>
                </v-col>
                <v-col class="textadjust">
                  <p
                    @click="clickLink(detailItem.link)"
                    style="
                      font-size: 18px;
                      opacity: 0.8;
                      cursor: pointer;
                      text-decoration: underline;
                      color: blue;
                      word-break: break-all;
                    "
                    class="mb-0"
                  >
                    {{ showlink }}
                  </p>
                </v-col>
              </div>
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col></v-col>
            <v-col class="pt-0 d-flex justify-center">
              <v-card-actions class>
                <v-btn
                  v-if="showEnroll && eventDateCheck"
                  depressed
                  class="login-phone text-capitalize white--text"
                  :style="[
                    checkEnrollUser == 'Enrolled'
                      ? { 'background-color': '#008AFF' }
                      : { 'background-color': '#A6CC39' },
                  ]"
                  download
                  @click="checkToEnrollEvent()"
                  >{{
                    checkEnrollUser == "Enrolled"
                      ? $t("enrolled")
                      : $t("enroll")
                  }}</v-btn
                >
                <v-btn
                  v-if="showFull && eventDateCheck"
                  depressed
                  disabled
                  color="#E0BF0B"
                  style="font-size: 18px"
                  class="white--text text-capitalize full_disabledBtn"
                  download
                  >{{ $t("ButtonFullCourse") }}</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>

        <v-dialog
          width="600"
          v-model="enrollDialog"
          style="border-radius: 16px !important"
        >
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <v-row justify="center" no-gutters class="mt-2">
                <p style="font-size: 25px; color: #363636">{{ dialogText }}</p>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize mb-4"
                text
                style="
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                  color: #424242;
                  font-size: 20px;
                "
                @click="enrollDialog = false"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                color="#A6CC39"
                style="font-size: 20px; border-radius: 8px"
                class="text-capitalize white--text mb-4"
                @click="registerUserToEvent()"
                >{{ $t("confirm") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-card>

    <v-dialog v-model="loading" persistent width="700">
      <v-card style="background: transparent; box-shadow: unset">
        <v-card-text style="justify-content: center; display: flex">
          <!-- Loading Please Wait... -->
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
            color="#2f9a61"
            class="mb-0"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  data() {
    return {
      NullDescription: true,
      eventDateCheck: null,
      loading: true,
      showFull: false,
      todayDate: moment(new Date()).local().format("DD MMM YYYY"),
      dialogText: "Are you sure you want to register?",
      enrollDialog: false,
      noOfAllowParticipants: 0,
      remainingUserSpot: 0,
      joinUser: "",
      remainingseat: "",
      showEnroll: false,
      checkEnrollUser: null,
      userItem: [],
      eventEndDate: "",
      eventStartDate: "",
      course_time: "",
      detailItem: [],
      showlink: null,
      breadcrumbs: [
        {
          text: "event",
          href: "/event",
        },
      ],
    };
  },
  mounted() {
    this.getFavourite();
    this.getNotificationCount();
    this.getEventDetailByCourseId();
    if (this.$store.state.library.notificationTransactionIDForEvent != 0) {
      this.Readnoti(
        this.$store.state.library.notificationTransactionIDForEvent,
        6
      );
    }
  },
  methods: {
    clickLink(link) {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "http://" + link;
      } //link wont open if it start with www.example.com

      window.open(link, "_blank");
    },

    checkToEnrollEvent() {
      this.dialogText =
        this.checkEnrollUser == "Enrolled"
          ? this.$t("cancelenroll")
          : this.$t("wantregister");

      this.enrollDialog = true;
    },
    truncateString(str) {
      if (str.length > 50) {
        return str.substring(0, 50) + "...";
      } else {
        return str;
      }
    },
    async registerUserToEvent() {
      let self = this;
      this.enrollDialog = false;
      this.loading = true;

      let request = {
        courseID: parseInt(this.$route.query.course_id),
        userID: parseInt(localStorage.getItem("UserID")),
        joinCourse: this.checkEnrollUser == "Enrolled" ? false : true,
        date: moment(new Date()).local().format("DD/MM/YYYY HH:mm:ss"),
        inboxFlag: true,
        pushNotiFlag: true,
        emailFlag: true,
      };
      await this.$axios
        .post(`${this.web_urlV2}Courses/UpdateEnroll`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.getEventDetailByCourseId();
            self.enrollDialog = false;
          }
        });
    },

    //after enroll call this function...
    async getEnrolledUsers() {
      let self = this;
      let request = {
        companyId: localStorage.getItem("companyID"),
        courseID: parseInt(this.$route.query.course_id),
      };
      await self.$axios
        .post(`${self.web_urlV2}Courses/GetJoinUsersByCourseID`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            self.userItem = response.data.data;
            let checkEnrollment = null;
            checkEnrollment = self.userItem.find(
              (x) => x.userID == parseInt(localStorage.getItem("UserID"))
            )
              ? true
              : false; // find if current user is already in enrolled user list..
            //*if current user is already registered Full btn will never show
            self.joinUser = self.userItem.length;
            // .$t("lblJoin")
            // .replace("{0}", self.userItem.length + "+");
            if (checkEnrollment) {
              // current user is in the enrolled userlist
              // therefore Full btn will not show and only Enrolled btn will show
              self.showFull = false;
              self.showEnroll = true;

              self.remainingUserSpot =
                self.noOfAllowParticipants - self.userItem.length;
              self.remainingseat = self.remainingUserSpot;
              // .$t("lblremainingseat")
              // .replace("{0}", self.remainingUserSpot);
            } else {
              // current user is not in the enrolled userlist
              // >> if there is still remaining spot Full btn will not show
              // else if there is no remaining spot Full btn will show

              self.remainingUserSpot =
                self.noOfAllowParticipants - self.userItem.length; // calculate remaining spots of the event...
              self.remainingseat = self.remainingUserSpot;
              // self
              // .$t("lblremainingseat")
              // .replace("{0}", self.remainingUserSpot);
              //whether to show Full event button or not
              self.showFull = self.remainingUserSpot == 0 ? true : false;
              self.showEnroll = self.showFull === true ? false : true;
            }

            self.loading = false;
          } else {
            self.loading = false;
          }
        })
        .catch(function (err) {
          self.loading = false;
          throw err;
        });
    },

    async getEventDetailByCourseId() {
      let self = this;
      self.loading = true;
      
      let request = {
        userID: parseInt(localStorage.getItem("UserID")),
        courseID: parseInt(this.$route.query.course_id),
      };
      await self.$axios
        .post(`${self.web_urlV2}Courses/GetCourseByID`, request)
        .then(function(res) {
          if(res.data.status === 0) {
          self.detailItem = res.data.data;
          
          self.NullDescription = res.data.data.wordDescription === "" || res.data.data.wordDescription === null;
          self.noOfAllowParticipants = self.detailItem.noOfParticipants;
          self.showlink = (self.detailItem.link != null && self.detailItem.link !='') ? self.truncateString(self.detailItem.link): null;
          self.course_time = moment(self.detailItem.courseTime)
            .local()
            .format("LT");
          self.eventStartDate = moment(self.detailItem.startContentDate)
            .local()
            .format("DD/MM/YYYY");
          self.eventEndDate = moment(self.detailItem.endContentDate)
            .local()
            .format("DD/MM/YYYY");

            let today = new Date();
            const endday = new Date(self.detailItem.endContentDate);
            endday.setHours(endday.getHours() + 23);
            endday.setMinutes(endday.getMinutes() + 59);

            self.eventDateCheck =
              today.getTime() >= endday.getTime()
                ? (self.showEnroll = false)
                : (self.showEnroll = true);

            //check if user has already enrolled.
            self.checkEnrollUser =
              self.detailItem.joinCourse === true ? "Enrolled" : "Enroll";             
            self.getEnrolledUsers();
          } else {
            self.loading = false;
          }
          
        });
    },
    Readnoti(notificationid, pageid) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        notificationID: notificationid,
        notiPage: pageid,
      };
      axios
        .post(`${self.web_url}Notification/AddUserIDinNotiJoin`, request)
        .then(function (result) {
          if (result.data.status == 0) {
            self.getNotificationCount();
          }
        });
    },
    async getNotificationCount() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };
      await axios
        .post(
          `${self.web_urlV3}Notification/GetAllNotificationMobileCountV4`,
          request
        )
        .then(function (result) {
          if (result.data.status == 0) {
            let d1 = result.data.data.filter(
              (x) => x.viewOrNot === false
            ).length;
            let d2 = result.data.data1.filter(
              (x) => x.newNoti === false
            ).length;
            let d3 = result.data.data2.filter(
              (x) => x.newNoti === false
            ).length;
            let d4 = result.data.data4.filter(
              (x) => x.newNoti === false
            ).length;
            let d5 = result.data.data5.filter(
              (x) => x.newNoti === false
            ).length;
            let d6 = result.data.data6.filter(
              (x) => x.newNoti === false
            ).length;
            let d7 = result.data.data7.filter(
              (x) => x.newNoti === false
            ).length;
            let d8 = result.data.data8.filter(
              (x) => x.newNoti === false
            ).length;
            let notiCount = d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8;
            self.$store.commit("savenotiCount", notiCount);
          }
        });
    },
  },
};
</script>

<style scoped>
.breaktext {
  white-space: pre-line;
}
.login-phone {
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.textadjust {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.orgImg {
  flex: 1;
  object-fit: cover;
  border-radius: 20px;
}
.img_container {
  height: 450px !important;
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

@media (max-width: 1440px) {
  .textadjust {
    padding-top: 2px !important;
    padding-bottom: 3px !important;
  }
  .img_container {
    height: 400px !important;
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: unset; /*1185px;*/
  }
}

@media (min-width: 960px) {
  .container {
    max-width: unset; /*900px;*/
  }
}

::v-deep .full_disabledBtn.theme--light.v-btn.v-btn--disabled {
  background-color: #e0bf0b !important;
  color: #ffffff !important;
}
::v-deep .v-dialog {
  box-shadow: unset !important;
}
::v-deep .v-btn__content {
  padding-top: 2px !important;
}
</style>
